import React, { useState, useEffect } from "react";

const Home = () => {
  const imageUrls = [
    // "assets/img/slide3.jpg",
    "assets/img/slide1.jpg",
    "assets/img/slide2.jpg",
  ];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === imageUrls.length - 1 ? 0 : prevIndex + 1
      );
    }, 4000); // Change image every 3 seconds

    return () => clearInterval(interval);
  }, );

  const [formData, setFormData] = useState({
    Your_Name: '',
    EMAIL: '',
    PHONE: '',
    MESSAGE: '',
  });
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  
  const handleSubmit = (e) => {
    e.preventDefault();
  
    // Check if any of the form fields are empty or null
    for (const key in formData) {
      if (formData[key] === '' || formData[key] === null) {
        alert(`${key} is empty or null. Please fill in all fields.`);
        return; // Stop form submission if any field is empty or null
      }
    }
  
    // If all fields are filled, proceed with form submission
    console.log('FormData:', formData);
    fetch('https://aminfowebfoundation.org/index.php', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        formData
      })
    })
      .then((response) => {
        console.log('Response:', response);
        if (response.status === 200) {
          alert("Form submission successfully");
        } else {
          alert("Form submission failed");
        }
      })
      .then((data) => {
        // Handle the response data here
        console.log('Form submitted successfully');
        console.log('Response data:', data);
        // You can update your UI or show a success message here
      })
      .catch((error) => {
        console.error('Error:', error);
        // Handle errors or display an error message to the user
      });
  };
  return (
    <React.StrictMode>
      <div className="main-page-content">
        <div id="home">
          <div id="particles-js"></div>
          <div className="home-content-main">
            <div className="table-cell">
              {/* <div className="container"> */}
              <div className="row home-row">
                <div className="col-md-12 col-sm-12">
                  <div className="home-text wow fadeIn text-center">
                    <div className="image-container">
                    {imageUrls.map((imageUrl, index) => (
                      <img
                        key={index}
                        src={imageUrl}
                        alt={`Image ${index}`}
                        className={index === currentImageIndex ? "is-visible" : "is-hidden"}
                      />
                    ))}
                    </div>
                  </div>
                </div>
              </div>
              {/* </div> */}
            </div>
          </div>
          {/* <div className="parallax" data-velocity="-.1"></div>
          <div className="parallax" data-velocity="-.5" data-fit="525"></div> */}
        </div>

        {/* <!-- ================================ ABOUT =============================== --> */}

        <div id="about">
          <div className="about-content">
            <div className="love-grid text-center">
              {/* <div className="container"> */}
                <div className="row">
                  <div className="col-md-12">
                    <div className="main-title text-center wow fadeIn">
                      <h1>We Believe In Giving Back</h1>
                      <div className="underline1"></div>
                      <div className="underline2"></div>
                      <p>
                      Our Founder and CEO, Ali Merchant always emphasizes on the importance of giving back to the society. He believes that each of us can contribute in our own way, and every little contribution helps. With this philanthropic motive, he established the AM Infoweb Foundation, a non-profit social welfare organization which aims to help the underprivileged and the less fortunate through financial aid, healthcare initiatives, livelihood generation and much more. Today, we have made a difference in the lives of thousands of people through our social welfare initiatives.
                      </p>
                    </div>
                  </div>
                </div>
              {/* </div> */}
            </div>
          </div>
        </div>

     
        {/* <!-- ============================================== SERVICE ===================================================== --> */}

        <div id="service">
          <div className="service-content">
            <div className="service-grid text-center">
              {/* <div className="container"> */}
                <div className="row">
                  <div className="col-md-12">
                    <div className="main-title text-center wow fadeIn">
                      <h3>Food Donation</h3>
                      <div className="underline1"></div>
                      <div className="underline2"></div>
                      <p>
                      Each one of us should take time to enrich our community. Food is one of the basic necessities of all living beings. “Only when your body is nourished, you can make a positive difference in your life”, says Ali Merchant, thus we often organize food donation drives which are personally spearheaded by our Founder. Let us consciously work towards a sustainable solution which ensures that no one starves.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row love-row wow fadeIn">
                  <div className="col-md-4 col-sm-6">
                    <div className="service-details" data-wow-delay=".1s">
                      <div className="service-head">
                        <img
                          src="assets/img/food_donation/1.jpg"
                          alt="food donation"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div className="service-details" data-wow-delay=".1s">
                      <div className="service-head">
                        <img
                          src="assets/img/food_donation/2.jpg"
                          alt="food donation"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div className="service-details" data-wow-delay=".1s">
                      <div className="service-head">
                        <img
                          src="assets/img/food_donation/3.jpg"
                          alt="food donation"
                        />
                      </div>
                    </div>
                  </div>
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>

 {/* <!-- ============================================== SERVICE ===================================================== --> */}

 <div id="service">
          <div className="service-content">
            <div className="service-grid text-center">
              {/* <div className="container"> */}
                <div className="row">
                  <div className="col-md-12">
                    <div className="main-title text-center wow fadeIn">
                      <h3>School Charity</h3>
                      <div className="underline1"></div>
                      <div className="underline2"></div>
                      <p>
                      Children are the future of this world. Every child must have the opportunity to grow in an environment conducive to their success. This thought encourages us to undertake initiatives which support the education of underprivileged children. We recently interacted with these amazing children in their school. We have committed to devote our time and monetary resources to improve their infrastructure and make sure that the school does not face any hiccups in meeting their daily needs and expenses.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row love-row wow fadeIn">
                  <div className="col-md-4 col-sm-6">
                    <div className="service-details" data-wow-delay=".1s">
                      <div className="service-head">
                        <img
                          src="assets/img/school_charity/1.jpg"
                          alt="school charity"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div className="service-details" data-wow-delay=".1s">
                      <div className="service-head">
                        <img
                          src="assets/img/school_charity/2.jpg"
                          alt="school charity"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div className="service-details" data-wow-delay=".1s">
                      <div className="service-head">
                        <img
                          src="assets/img/school_charity/3.jpg"
                          alt="school charity"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            {/* </div> */}
          </div>
        </div>
        {/* <!-- ============================================== SERVICE ===================================================== --> */}

 <div id="service">
          <div className="service-content">
            <div className="service-grid text-center">
              {/* <div className="container"> */}
                <div className="row">
                  <div className="col-md-12">
                    <div className="main-title text-center wow fadeIn">
                      <h3>Medical Health Camp</h3>
                      <div className="underline1"></div>
                      <div className="underline2"></div>
                      <p>
                      Today good health has become a luxury. Our medical health camp was aimed to educate the masses about the importance of good health and also diagnose any existing deficiencies and illnesses. The overwhelming response for this initiative has inspired us to conduct such camps regularly.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row love-row wow fadeIn">
                  <div className="col-md-4 col-sm-6">
                    <div className="service-details" data-wow-delay=".1s">
                      <div className="service-head">
                        <img
                          src="assets/img/medical_health_camp/1.jpg"
                          alt="school charity"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div className="service-details" data-wow-delay=".1s">
                      <div className="service-head">
                        <img
                          src="assets/img/medical_health_camp/2.jpg"
                          alt="school charity"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div className="service-details" data-wow-delay=".1s">
                      <div className="service-head">
                        <img
                          src="assets/img/medical_health_camp/3.jpg"
                          alt="school charity"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            {/* </div> */}
          </div>
        </div>
        {/* <!-- ============================================== SERVICE ===================================================== --> */}

 <div id="service">
          <div className="service-content">
            <div className="service-grid text-center">
              {/* <div className="container"> */}
                <div className="row">
                  <div className="col-md-12">
                    <div className="main-title text-center wow fadeIn">
                      <h3>Blood Donation Drive</h3>
                      <div className="underline1"></div>
                      <div className="underline2"></div>
                      <p>
                      Our regular blood donation campaigns have been able to successfully educate, mobilize, and inspire people to donate blood. We are extremely fortunate to be surrounded by wonderful human beings who never hesitate to volunteer and donate their blood at our donation drives.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row love-row wow fadeIn">
                  <div className="col-md-4 col-sm-6">
                    <div className="service-details" data-wow-delay=".1s">
                      <div className="service-head">
                        <img
                          src="assets/img/blood_donation/1.jpg"
                          alt="school charity"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div className="service-details" data-wow-delay=".1s">
                      <div className="service-head">
                        <img
                          src="assets/img/blood_donation/2.jpg"
                          alt="school charity"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div className="service-details" data-wow-delay=".1s">
                      <div className="service-head">
                        <img
                          src="assets/img/blood_donation/3.jpg"
                          alt="school charity"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            {/* </div> */}
          </div>
        </div>
        {/* <!-- ============================================== SERVICE ===================================================== --> */}

 <div id="service">
          <div className="service-content">
            <div className="service-grid text-center">
              {/* <div className="container"> */}
                <div className="row">
                  <div className="col-md-12">
                    <div className="main-title text-center wow fadeIn">
                      <h3>Eye Check-up</h3>
                      <div className="underline1"></div>
                      <div className="underline2"></div>
                      <p>
                      Our eyes are our window to the world. Poor genetics or diseases can lead to deteriorating eyesight even if you are not very old. Our eye check-up camp was organized for early detection of macular degeneration, glaucoma, among other illnesses. We are happy to state that our initiative and early intervention helped hundreds of people avoid permanent blindness.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row love-row wow fadeIn">
                  <div className="col-md-4 col-sm-6">
                    <div className="service-details" data-wow-delay=".1s">
                      <div className="service-head">
                        <img
                          src="assets/img/eye_check_up/1.jpeg"
                          alt="school charity"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div className="service-details" data-wow-delay=".1s">
                      <div className="service-head">
                        <img
                          src="assets/img/eye_check_up/2.jpeg"
                          alt="school charity"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div className="service-details" data-wow-delay=".1s">
                      <div className="service-head">
                        <img
                          src="assets/img/eye_check_up/3.jpeg"
                          alt="school charity"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            {/* </div> */}
          </div>
        </div>
        {/* <!-- ============================================== SERVICE ===================================================== --> */}

 <div id="service">
          <div className="service-content">
            <div className="service-grid text-center">
              {/* <div className="container"> */}
                <div className="row">
                  <div className="col-md-12">
                    <div className="main-title text-center wow fadeIn">
                      <h3>Ration Kit Distribution</h3>
                      <div className="underline1"></div>
                      <div className="underline2"></div>
                      <p>
                      Widowed women are faced with increased health risks and chronic conditions associated not only with bereavement, but also, due to loss and grief, adjusting to living alone, decreased income, and managing multiple chronic conditions. This thought encourages us to undertake initiatives which supports their livelihood. We have committed to devote our time and effective services to foster their life transition.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row love-row wow fadeIn">
                  <div className="col-md-4 col-sm-6">
                    <div className="service-details" data-wow-delay=".1s">
                      <div className="service-head">
                        <img
                          src="assets/img/ration_distribution/1.jpg"
                          alt="school charity"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div className="service-details" data-wow-delay=".1s">
                      <div className="service-head">
                        <img
                          src="assets/img/ration_distribution/2.jpg"
                          alt="school charity"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div className="service-details" data-wow-delay=".1s">
                      <div className="service-head">
                        <img
                          src="assets/img/ration_distribution/3.jpg"
                          alt="school charity"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            {/* </div> */}
          </div>
        </div>

        {/* <!-- ================================ CONTACT ========================== --> */}

        <div id="contact">
          <div className="contact-content">
            <div className="text-grid">
              <div className="text-grid-main">
                <div className="row">
                  <div className="col-md-12 col-sm-12">
                    <div className="main-title text-center wow fadeIn">
                      <h3>Contact Us</h3>
                      <div className="underline1"></div>
                      <div className="underline2"></div>
                      <p>
                      We’d Love to Hear From You, Get In Touch With Us!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="contact-grid">
              <div className="contact-form-details wow fadeIn">
                <div className="container">
                  <div className="row contact-row">
                  <div className="col-md-1 col-sm-6 ">                     
                    </div>
                    <div className="col-md-10 col-sm-12 contact-col">
                      <h3>Write Your Message Here</h3>
                      <div className="contact-form">
                      <form onSubmit={handleSubmit}
                          action=""
                          method="post"
                          id="mc-embedded-subscribe-form contactForm"
                          name="mc-embedded-subscribe-form"
                          className="contact-form shake validate"
                          target="_blank" 
                          data-toggle="validator"
                          novalidate
                        >
                          <div className="form-group">
                            <div className="controls">
                              <input
                                type="text"
                                name="Your_Name"
                                value={formData.Your_Name}
                                onChange={handleChange}
                                id="mce-Your_Name"
                                className="required form-control"
                                placeholder="Your Name"
                                required
                                data-error="Please enter your name"
                              />
                              <div className="help-block with-errors"></div>
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="controls">
                              <input
                                type="email"
                                className="email required form-control"
                                name="EMAIL"
                                value={formData.EMAIL}
                                onChange={handleChange}
                                id="mce-EMAIL"
                                placeholder="Your Email"
                                required
                                data-error="Please enter your email"
                              />
                              <div className="help-block with-errors"></div>
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="controls">
                              <input
                                type="phone"
                                name="PHONE"
                                value={formData.PHONE}
                                onChange={handleChange}
                                className="phone form-control"
                                id="phone mce-PHONE"
                                placeholder="Your Phone"
                                required
                                data-error="Please enter your phone"
                              />
                              <div className="help-block with-errors"></div>
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="controls">
                              <textarea
                                id="message mce-MESSAGE"
                                rows="7"
                                placeholder="Your Massage"
                                name="MESSAGE"
                                value={formData.MESSAGE}
                                onChange={handleChange}
                                className="required form-control"
                                required
                                data-error="Please enter your message"
                              ></textarea>
                              <div className="help-block with-errors"></div>
                            </div>
                          </div>
                          <div id="mce-responses" class="clear">
                            <div
                              class="response"
                              id="mce-error-response"
                              style={{ display: "none" }}
                            ></div>
                            <div
                              class="response"
                              id="mce-success-response"
                              style={{ display: "none" }}
                            ></div>
                          </div>
                          <div
                            style={{ position: "absolute", left: "-5000px" }}
                            aria-hidden="true"
                          >
                          </div>
                          <button
                            type="submit"
                            value="Subscribe"
                            name="subscribe"
                            id="submit mc-embedded-subscribe"
                            className="btn btn-success"
                          >
                            Send Message
                          </button>
                          <div
                            id="msgSubmit"
                            className="h3 text-center hidden"
                          ></div>
                          <div className="clearfix"></div>
                        </form>
                      </div>
                    </div>
                    <div className="col-md-1 col-sm-6 ">                     
                    </div>
                  </div> 
                  <div className="row contact-info-row text-center wow fadeIn">
                    
                  </div>
                </div>
                <div class="container-fluid map-col">
                <div className="row love-row wow fadeIn">
                  <div class="col-md-6 col-sm-6 map-col">
                    <div style={{ border: "0" ,paddingLeft: "5%",paddingRight: "1%"}}>
                      <h3>Located In India</h3>
                      <p><strong>India:</strong> B-3, 7th Floor, Cerebrum IT Park, Kalyani Nagar, Pune, Maharashtra, India. 411014</p>
                    </div>
                    <div class="google-maps">
                      <div class="map-wrap">
                        <iframe
                          title="google-maps"
                          src="https://maps.google.com/maps?width=100%&height=400&hl=en&q=B-3%2C%203rd%20Floor%2C%20Cerebrum%20IT%20Park%2C%20Kalyani%20Nagar%2C%20Pune%2C%20Maharashtra%2C%20India.+(B-3%2C%203rd%20Floor%2C%20Cerebrum%20IT%20Park%2C%20Kalyani%20Nagar%2C%20Pune%2C%20Maharashtra%2C%20India.)&ie=UTF8&t=&z=14&iwloc=B&output=embed"
                          width="100%"
                          height="450"
                          style={{ border: "0" ,paddingLeft: "5%",paddingRight: "1%"}}
                          allowfullscreen=""
                          loading="lazy"
                          referrerpolicy="no-referrer-when-downgrade"
                        ></iframe>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 col-sm-6 map-col">
                    <div tyle={{ border: "0" ,paddingLeft: "1%",paddingRight: "5%" }}>
                      <h3>Located In United States</h3>
                      <p> <strong>United States:</strong> 30 Wall Street, 8th Floor, New York City, New York 10005</p>
                    </div>
                    <div class="google-maps">
                      <div class="map-wrap">
                        <iframe
                          title="google-maps"
                          src="https://maps.google.com/maps?q=30%20Wall%20Street%2C%20New%20York%20City%2C&t=&z=13&ie=UTF8&iwloc=&output=embed"
                          width="100%"
                          height="450"
                          style={{ border: "0" ,paddingLeft: "1%",paddingRight: "5%" }}
                          allowfullscreen=""
                          loading="lazy"
                          referrerpolicy="no-referrer-when-downgrade"
                        ></iframe>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.StrictMode>
  );
};

export default Home;
