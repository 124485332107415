import React from "react";
var d = new Date();
var currYear = d.getFullYear();

function Footer() {
  return (
    <React.StrictMode>
      <div className="copyright-details">
        <div className="about-social-icon text-center">
          <ul className="about-social">
            <li className="wow fadeIn" data-wow-delay=".2s">
              <a href="#" target="newtab">
                <i className="fa fa-facebook" aria-hidden="true"></i>
              </a>
            </li>
          </ul>
        </div>
        <div className="copyright">
          <h3>Develop & Design by {currYear} &copy; <a href="https://www.aminfoweb.in/" target="newtab" style={{ color:"#fbfbfb"}}>Aminfoweb</a></h3>
        </div>
      </div>
    </React.StrictMode>
  );
}

export default Footer;
